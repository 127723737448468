import Comparison from './imports/comparison';
import HeroPattern from './imports/hero-pattern';
import ResponsiveTable from './imports/responsive-table';
import ScrollSpy from './imports/scroll-spy';
import Tabs from './imports/tabs';

(() => {
  if(window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
  }
  if(window.HTMLCollection && !HTMLCollection.prototype.forEach) {
    HTMLCollection.prototype.forEach = Array.prototype.forEach;
  }
  
  Comparison();
  HeroPattern();
  Tabs();
  ScrollSpy();
  ResponsiveTable();
})();