const ResponsiveTable = () => {
  const tables = Array.from(document.querySelectorAll('.c-table--responsive'));
  tables.forEach(createResponsiveTable);

  /**
   * Create responsive table headers for large tables
   * @param {HTMLTableElement} table
   */
  function createResponsiveTable(table) {
    const headers = table.querySelectorAll('thead > :first-child th');
    const breakPoint = Number(table.getAttribute('data-columns')) || 2
  
    if (headers.length < breakPoint) {
      table.classList.remove('c-table--responsive');
      return;
    };

    Array.from(table.querySelectorAll('tbody tr')).forEach((row) => {
      Array.from(headers)
        .map((x) => x.textContent)
        .forEach((header, index) => {
          if (row.children[index]) {
            row.children[index].setAttribute('data-responsive-title', header);
          }
        });
    });
  }
};

export default ResponsiveTable;
