function ScrollSpy() {
  const spies = Array.from(document.querySelectorAll('.c-scroll-spy'));
  spies.forEach(setup);
}

function setup(element) {
  const anchors = Array.from(element.querySelectorAll('a'));
  const observer = new IntersectionObserver(observation(anchors));
  
  anchors.forEach(anchor => {
    const id = anchor.getAttribute('href').replace('#', '');
    const target = document.getElementById(id);
    if (!target) return;

    observer.observe(target);
  });
}

/**
 * @param {Array<HTMLAnchorElement>} results 
 * @return {Array<IntersectionObserverEntry>}
 */
function observation(anchors) {
  return (results) => {
    const firstObservation = results.find(x => x.isIntersecting);

    if (firstObservation) {
      anchors.forEach(x => {
        x.classList.toggle(
          'c-scroll-spy-active',
          x.getAttribute('href') === '#' + firstObservation.target.id
        )
      })
    }
  }
}

export default ScrollSpy;
