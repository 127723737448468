function Comparison() {
  document.querySelectorAll('.c-comparison').forEach(extractMobileComparison)
}

function extractMobileComparison(container) {
  const body = container.querySelector('.c-table tbody');
  const head = container.querySelector('.c-table thead');
  const mobile = container.querySelector('.c-comparison__mobile');

  if (!body || !head || !mobile) return;

  // Remove empty corner piece
  const products = Array.from(head.children[0].children)
    .filter((x, i) => i)
    .map(x => ({ info: x.innerHTML, table: [] }));

  Array.from(body.children).forEach(row => {
    // For table row headers, push to all products
    if (row.classList.contains('c-table__title')) {
      products.forEach(x => {
        x.table.push({ type: 'title', title: row.children[0].textContent })
      })
    } else {
      // For all others, loop the cells and push to their appropriate parent
      const cells = Array.from(row.children);
      const firstCell = cells.shift();

      cells.forEach((el, index) => {
        products[index].table.push({
          type: 'row',
          key: firstCell.textContent,
          value: el.innerHTML
        })
      })
    }
  })

  products.forEach(product => {
    const el = createMobileProduct(product);
    mobile.appendChild(el)
  })
}

function createMobileProduct(product) {
  const parent = document.createElement('article');
  parent.innerHTML = `<div class="c-comparison__content">
    ${product.info}
  </div>
  <table class="c-table">
    ${product.table.map(x => {
      if (x.type === 'title') {
        return `<tr class="c-table__title"><th colspan="2">${x.title}</th></tr>`;
      } else {
        return `<tr>
          <td>${x.key}</td>
          <td>${x.value}</td>
        </tr>`;
      }
    }).join('')}
  </table>`;

  return parent;
}

export default Comparison;
